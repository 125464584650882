import Footer from "../landingPage/components/Footer"
import ContactHero from "./ContactHero"
import TestCompon from "./TestCompon"

const Contact = () => {

    return (
        <>
            <ContactHero />

            {/* <TestCompon /> */}
            <Footer />
        </>
    )
}

export default Contact